import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import TextButton from "../Components/TextButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function PreviousNameForm({ submit, submitText, cancel }) {
  const registrant = useSelector((state) => state.registrant);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      previous_last_name: '',
      previous_first_name: '',
      previous_middle_name: '',
    }
  });

  React.useEffect(() => {
    const {
        previous_first_name,
        previous_last_name,
        previous_middle_name,
    } = registrant;

    setValue('previous_first_name', previous_first_name);
    setValue('previous_last_name', previous_last_name);
    setValue('previous_middle_name', previous_middle_name);
}, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex ">
        <div className="flex-1 p-2">
          <label>{getContent('fields.previous_first_name')}*</label>
          <Controller
            name="previous_first_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="Jane" type="text" autoComplete="off" maxLength="30" className={`${errors.previous_first_name ? 'error' : ''}`} />}
          />
          {errors.previous_first_name?.type === 'required' && <span className="error">{getContent('validation.previous_first_name')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.previous_last_name')}*</label>
          <Controller
            name="previous_last_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="Doe" type="text" autoComplete="off" maxLength="30" className={`${errors.previous_last_name ? 'error' : ''}`} />}
          />
          {errors.previous_last_name?.type === 'required' && <span className="error">{getContent('validation.previous_last_name')}</span>}
        </div>
      </div>
      <div className="flex ">
        <div className="flex-1 p-2">
          <label>{getContent('fields.previous_middle_name')}</label>
          <Controller
            name="previous_middle_name"
            control={control}
            render={({ field }) => <input {...field} placeholder="Henry" type="text" autoComplete="off" maxLength="30" />}
          />
        </div>
      </div>
    


      <div className="flex justify-end pt-8 pb-2">
        <TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>
        <PrimaryButton submit>{ !submitText ? getContent('buttons.next_step') : submitText }</PrimaryButton>
      </div>
    </form>
  );
}

export default PreviousNameForm;