import React from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { isValidPhoneNumber, isValidEmail } from '../helpers';
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function StayInTouchForm({ submit, disabled }) {
  const registrant = useSelector((state) => state.registrant);
  const partner = useSelector((state) => state.partner);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email_address: '',
      email_address_optin: '',
      phone_number: '',
      phone_number_optin: '',
      phone_type: '',
    }
  });

  React.useEffect(() => {
    const {
        first_name,
        last_name,
        email_address,
        email_address_optin,
        phone_number,
        phone_number_optin,
        phone_type,
    } = registrant;

    setValue('first_name', first_name);
    setValue('last_name', last_name);
    setValue('email_address', email_address);
    setValue('email_address_optin', email_address_optin);
    setValue('phone_number', phone_number);
    setValue('phone_number_optin', phone_number_optin);
    setValue('phone_type', phone_type);

}, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex ">
        <div className="flex-1 p-2">
          <label>{getContent('fields.first_name')}*</label>
          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="Jane" type="text" autoComplete="off" maxLength="30" className={`${errors.first_name ? 'error' : ''}`} />}
          />
          {errors.first_name?.type === 'required' && <span className="error">{getContent('validation.first_name')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.last_name')}*</label>
          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="Doe" type="text" autoComplete="off" maxLength="30" className={`${errors.last_name ? 'error' : ''}`} />}
          />
          {errors.last_name?.type === 'required' && <span className="error">{getContent('validation.last_name')}</span>}
        </div>
      </div>
      <div className="flex-1 p-2  ">
        <label>{getContent('fields.email_address')}</label>
        <Controller
          name="email_address"
          control={control}
          rules={{
            validate: {
              email: value => !value || (value && isValidEmail(value) || 'Valid Email is required')
            }
          }}
          render={({ field }) => {
            return (
              <input {...field} placeholder="you@example.com" type="text" autoComplete="off" maxLength="50" className={`${errors.email_address ? 'error' : ''}`} />
            );
          }}
        />
        {errors.email_address?.type === 'email' && <span className="error">{getContent('validation.email_address_format')}</span>}
      </div>
      <div className="flex flex-col sm:flex-row justify-end sm:justify-between p-2 sm:items-center">
        <Controller
          name="email_address_optin"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.email_address_optin} /><span></span> <span>{partner['email_optin_'+currentLanguage]}</span></label>}
        />
        <Link className="text-xs underline" to="/privacy-policy">{getContent('general.privacy_policy')}</Link>
      </div>
      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.phone_number')}</label>
          <Controller
            name="phone_number"
            control={control}
            rules={{
              validate: {
                phone: value => !value || (value && isValidPhoneNumber(value) || getContent('validation.phone_number_valid'))
              }
            }}
            render={({ field }) => {
              return (
                <InputMask placeholder="555-555-5555" className={`input ${errors.phone_number ? 'error' : ''}`} {...field} mask="999-999-9999" />
              );
            }}
          />
          {errors.phone_number?.type === 'required' && <span className="error">{getContent('validation.phone_number_required')}</span>}
          {errors.phone_number?.type === 'phone' && <span className="error">{getContent('validation.phone_number_valid')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>&nbsp;</label>
          <Controller
            name="phone_type"
            control={control}
            rules={{
              required: !getValues('phone_number') ? false : true
            }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.phone_type ? 'error' : ''}`} >
                  <select {...field}>
                    <option value="" disabled>{getContent('fields.phone_type_select_label')}</option>
                    <option value="">{getContent('fields.phone_type_select')}</option>
                    <option value="cell">{getContent('fields.cell_phone')}</option>
                    <option value="home">{getContent('fields.home_phone')}</option>
                    <option value="work">{getContent('fields.work_phone')}</option>
                  </select>
                </div>
              );
            }}
          />
          {errors.phone_type?.type === 'required' && <span className="error">{getContent('validation.phone_number_valid')}</span>}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-end sm:justify-between p-2 sm:items-center">
        <Controller
          name="phone_number_optin"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.phone_number_optin} /><span></span> <span>{partner['phone_optin_'+currentLanguage]}</span></label>}
        />
        <Link className="text-xs underline" to="/privacy-policy">{getContent('general.privacy_policy')}</Link>
      </div>
      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit disabled={disabled}>{getContent('buttons.sign_up')}</PrimaryButton>
      </div>
    </form>
  );
}

export default StayInTouchForm;